import React, { useState, useEffect, useContext } from 'react'
import './LeagueLeaderboard.css'
import axios from 'axios';
import { DashboardComponent } from '../../components/Layout components/DashboardComponent/DashboardComponent'
import { DashboardHeaderComponent } from '../../components/Layout components/DashboardHeaderComponent/DashboardHeaderComponent';
import { Context } from '../../Context/UserContext';
import { useParams } from 'react-router-dom';

export const LeagueLeaderboard = () => {
  const [objectLeaguesTeams, setObjectLeaguesTeams]= useState([]);
  const context = useContext(Context);
  const {id} = useParams()

  const token = context.userData.token;
  const containerLeagueLeaderboard =()=>{
    axios.get(`https://agtsena.somee.com/Liguillas/equipos/${id}`, {
      headers:{
        'Content-Type': 'application/json',
        'Access-Control-Allow_Origin':'*',
        'Authorization': `BEARER ${token}`
    }
  })
  .then(response=>{
    setObjectLeaguesTeams(response.data);
    console.log(response.data);
  })
  .catch(error=>
    console.log(error)
  );
  }

  useEffect(() => { 
    containerLeagueLeaderboard()
  }, [])

    return (
      <div className='containerLeagueLeaderboard'>
        <h1 className='titleLeagueLeaderboard'>Tabla de Clasificación Liga {id}</h1>
        <DashboardHeaderComponent column1="Equipo" column2="Partidos Ganados" column3="Goles a favor" column4="Goles en contra" column5="Puntos" style1="spacingColumnLL " style3=" spacingColumnLL"  />
        <DashboardComponent objectData={objectLeaguesTeams} style1="spacignLL " style2="" type={3}/>
    </div>
  )
}
