import React,{useState,useEffect,useContext} from 'react';
import './Statistics.css';
import axios from 'axios';
import { MyDinamicBar } from '../../components/Styled components/DinamicBarStyled/DinamicBarStyled.js';
import { MyMenuCardStyled } from '../../components/Styled components/MenuCardsStyled/MenuCardsStyled.js';
import { Context } from '../../Context/UserContext.jsx';


export const Statistics = () => {
  const [statistics, setStatistics]= useState([]);
    const context = useContext(Context);

  const token = context.userData.token; 
  const getStatistics =()=>{
    axios.get('https://agtsena.somee.com/api/estadisticas', {
      headers:{
        'Content-Type': 'application/json',
        'Access-Control-Allow_Origin':'*',
        'Authorization': `BEARER ${token}`
    }
  })
  .then(response=>{
    setStatistics(response.data);
    console.log(response.data);
  })
  .catch(error=>
    console.log(error)
  );
  }
  useEffect(() => {
    getStatistics()
  }, [])

  console.log();
  return (
    <div className='containerStatistics scroll'>
        <h1 className='titleStatistics'>Datos de Interes</h1>
        <div className='bar_div'>
            <MyDinamicBar color="#ff6719" padding="0.1875rem" width="30%" radius="0.1875rem" left="1.25rem"/>
        </div>
        <div className='containerCards'>

        <div className='containerCardOne'>
            <MyMenuCardStyled radius="0.3125rem" shadowColor="#181818" padding="1.25rem"  className='styleCard'>
                <h4>Datos Básicos</h4>
                <div className='contentTextStatisticalCardsAlls'>
                    <p className='contentTextStatisticalCards'>Paticipantes del torneo: {statistics.map(data =>(data.participantes))}</p>
                    <p className='contentTextStatisticalCards'>Equipos del torneo: {statistics.map(data =>(data.equipos))}</p>
                    <p className='contentTextStatisticalCards'>Fechas transcurridas: {statistics.map(data =>(data.fechasJugadas))}</p>
                    <p className='contentTextStatisticalCards'>Fechas por jugar: {statistics.map(data =>(data.fechasPorJugar))}</p>
                    <p className='contentTextStatisticalCards'>Participantes totales expulsados: 0 </p>
                    <p className='contentTextStatisticalCards'>Equipos con expulsiones: 0 </p>
                </div>
            </MyMenuCardStyled>
            <MyMenuCardStyled radius="0.3125rem" shadowColor="#181818" padding="1.25rem" className='styleCard'>
                <h4>Datos de los Jugadores</h4>
                <div className='contentTextStatisticalCardsAlls'>
                    <p className='contentTextStatisticalCards'>Jugador más expulsado: David Torres</p>
                    <p className='contentTextStatisticalCards'>Jugador menos expulsado: Pablo Gaviria</p>
                    <p className='contentTextStatisticalCards'>Jugador con más faltas: David Torres</p>
                    <p className='contentTextStatisticalCards'>Jugador con menos faltas: Felipe Peña</p>
                    <p  className='contentTextStatisticalCards'>Jugador con más faltas recibidas: Juan Arias</p>
                    <p className='contentTextStatisticalCards'>Equipos con expulsiones: 2</p>
                </div>
                <div className='contentTextStatisticalCardsAlls'>
                    <p className='contentTextStatisticalCards'>Jugador menos goles: Alejandro</p>
                    <p className='contentTextStatisticalCards'> Jugador con más goles: Felipe Peña</p>
                    <p className='contentTextStatisticalCards'> Jugador con mas asistencias: Felipe Peña</p>
                </div>
                <div className='contentTextStatisticalCardsAlls'>
                    <p className='contentTextStatisticalCards'>Jugador con más salvadas: Felipe Peña</p>
                    <p className='contentTextStatisticalCards'>Jugadores lesionados: 0</p>
                    <p className='contentTextStatisticalCards'>Jugadores que se retiraron: 2</p>
                </div>
            </MyMenuCardStyled>
        </div>
        <div className='containerCardTwo'>
            <MyMenuCardStyled radius="0.3125rem" shadowColor="#181818" padding="1.25rem" className='styleCard'>
                <h4>Datos de los equipos</h4>
                <div className='contentTextStatisticalCardsAlls'>
                        <p className='contentTextStatisticalCards'>Promedio de expulsiones por equipo: 0.08</p>
                        <p className='contentTextStatisticalCards'>Equipo con más expulsiones: JuanFC</p>
                        <p className='contentTextStatisticalCards'>Equipo con menos expulsiones: FC Barcelona</p>
                        <p className='contentTextStatisticalCards'>Promedio de faltas por equipo: 4.2</p>
                        <p className='contentTextStatisticalCards'>Faltas totales del torneo: 114</p>
                        <p className='contentTextStatisticalCards'>Equipo con menos faltas: Fundadores</p>
                </div>
                <div className='contentTextStatisticalCardsAlls'>
                        <p className='contentTextStatisticalCards'>Equipo más goleado: JuanFC</p>
                        <p className='contentTextStatisticalCards'>Equipo más goleador: Fundadores</p>
                        <p className='contentTextStatisticalCards'>Equipo menos goleado: FC Barcelona</p>
                        <p className='contentTextStatisticalCards'>Equipo más imbatido: Fundadores</p>
                        <p className='contentTextStatisticalCards'>Promedio de goles por equipo: 3.8</p>
                        <p className='contentTextStatisticalCards'>Mayor cantidad de goles en un partido: 7</p>
                        <p className='contentTextStatisticalCards'>Equipo menos ganador: Real Madrid</p>
                </div>
            </MyMenuCardStyled>
        </div>
        </div>
        
    </div>
  )
}
