import React,{useState} from 'react'
import { SelectWinner } from '../../Styled components/SelectWinner/SelectWinner';
import { IoMdArrowDropdown } from 'react-icons/io';
import './Select.css';

export const Select = ({infoEquipo1, infoEquipo2, infoGanador}) => {
  const [winningTeam, setWinningTeam]=useState("Sin ganador");
  const [isOpen, setIsOpen] = useState(false);

  const changeName=(event)=>{
    setWinningTeam(event.target.textContent)
    setIsOpen(!isOpen)
  }

  return (
    <div className='positionRelative'>
      <div className='selectInitial' onClick={()=>{setIsOpen(!isOpen)}}>
        {
          infoGanador==null?
          <>
            <p className='text_clasification'>{winningTeam}</p> 
          </>
          :
          <>
            <p className='text_clasification'>{infoGanador}</p> 
          </>
        }        
        <IoMdArrowDropdown className='iconSelectOption'/>
      </div>
      <SelectWinner isOpen={isOpen} >
        <div className='overlay' onClick={()=>{setIsOpen(!isOpen)}} ></div>
        <div className='optionsWinner'>
        <p className='textOption' onClick={event=>changeName(event)}>Sin ganador</p>
          <p className='textOption' onClick={event=>changeName(event)}>{infoEquipo1}</p>
          <p className='textOption' onClick={event=>changeName(event)}>{infoEquipo2}</p>
        </div>
      </SelectWinner>
    </div>
  )
}