import React, { useState } from 'react'
import ButtonAsLink from '../../UI components/ButtonAsLink/ButtonAsLink'
import { ShadedBorder } from '../../Styled components/ShadedBorder/ShadedBorder'
import Input from '../../UI components/Input/Input'
import axios from "axios"
import { sendFileCsv } from '../../../apiRoutes'
import { useParams } from 'react-router-dom'
import Button from '../../UI components/Button/Button'


export default function Registry({ state, funct }) {
  
  /* new user registry */

  const [ tournamentName , setTournamentName ] = useState("");
  const [ userEmail , setUserEmail ] = useState("");
  const [ password , setPassword ] = useState("");
  const [ userName , setUserName ] = useState("");

  const setEventEmail= ((event) => {
    setUserEmail(event.target.value)
  })

  
  const setEventPassword = ((event) => {
    setPassword(event.target.value)
  })

  const setEventTournamentName = ((event) => {
    setTournamentName(event.target.value)
  })

  const [ file , setFile ] = useState(null);

  const validateUser = () =>{
     axios.get("https://agtsena.somee.com/api/UsuarioAdmin" , {params : { email : userEmail }})
    .then(res =>{
      if(res.data.length >= 1 ){
        console.log(res.data)
        console.log("usuario creado anteriormente");
      }else{
        register()
      }
    })
  }
  
  // const registerUser = () =>{
  //     axios.post("https://agtsena.somee.com/api/UsuarioAdmin",{
  //      "nombre" : tournamentName,
  //      "correo" : userEmail,
  //      "contrasenna" : password,
       
  //    })
  //    .then(res =>{
  //     console.log(res.status) djhjhgjhgjhgjhgjhgjhgjhgjhgjhgjhg
  //   })
  // }
  
  /* send CSV file */

  const changeFile =e=> {
    console.log(e);
    setFile(e);
  }

  const register = async () =>{
    
    const data = new FormData();

    for (let index = 0; index < file.length; index++){
      data.append("files" , file[index]);
    }
    await axios.post(`https://agtsena.somee.com/api/Archivo/${userName}/${userEmail}/${password}/${tournamentName}/1`, data)
    .then(response => {
      console.log(response.data);
    }).catch(error => {
      console.log(error);
    })
  }

  const sendData = () =>{
    // validateUser()
    register()

  }

  return (
    <div>
      <ShadedBorder radius="10px" shadowColor="#181818" bgColor="#292929" padding="5px">
        <div className='login_div'>
          <h3>Registro</h3>
          <div className='infoInputDiv'>
            <label>Nombre usuario</label>
            <Input type="text" onChange={e =>{setUserName(e.target.value) }}/>
            <label>Nombre torneo</label>
            <Input type="text" onChange={ setEventTournamentName }/>
            <label>Correo electronico</label>
            <Input type="email" onChange={ setEventEmail }/>
            <label>Contraseña</label>
            <Input type="password" onChange={ setEventPassword }/>
            <label className='participants-info'>insertar participantes</label>
            <label className='upload-clic' htmlFor='upload-file'>Seleccionar archivo</label>
            {/* file uploader */}
            <input
              type="file"
              name="file"
              accept=".csv"
              onChange={(e) => changeFile(e.target.files)}
              id="upload-file"
              className='uploadFile'
            />
          </div>
          <Button btnName="Registrarme" onClick={sendData} />
          <div>
            <ButtonAsLink btnName="¿Ya tienes cuenta? Inicia Sesión" isLoginState={!state} isLoginFunct={funct} />
          </div>
        </div>
        <div className='line_div'></div>
      </ShadedBorder>
    </div>
  )
}
