import React from 'react';
import './DashboardComponent.css';
import { Card } from '../../UI components/Card/Card';
import { Select } from '../../UI components/Select/Select';

export const DashboardComponent = ({objectData, style1, style2, style3, type, style4 }) => {
  const returnCards = ({type}) =>{
    if(type===1){
      return(
        objectData.map(data=>(
          <Card content1={data.fechaencuentro.substr(0,10)} 
          content2={data.equipo1} 
          content3={data.equipo2  }
          content4={data.equipoganador} 
          stylep1={style1} 
          stylep2={style2}
          url={`/match/${data.id}`}/>))
      )    
    }
    else if(type===2){
      return(

        objectData.map(data=>(
            <Card content1={"Liga " + data.id} 
            content2={data.equipos} 
            content3={data.equipotop} 
            stylep1={style1} 
            stylep2={style2}
            url={`/leagueLeaderboard/${data.id}`}
              stylep3={style3}
            />))
      )
    }
    else if(type===3){
      return(
        objectData.map(data=>(
            <Card content1={data.nombreequipo} 
            content2={data.partidosganados} 
            content3={data.golesafavor} 
            content4={data.golesencontra} 
            content5={data.partidosganados*3} 
            stylep1={style1} 
            stylep2={style2}
            stylep4={style4}
            />))
            
      )
    }
  }
    
  return (
    <div className='dashborard scroll'>
      {
        returnCards({type})
      }
   </div>
  )
}