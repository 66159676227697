import React from 'react'
import styled from 'styled-components'

export const MyDinamicBar = styled.div`
    width: ${props => props.width};
    background-color: ${props => props.color};
    padding: ${props => props.padding};
    border-radius: ${props => props.radius};
    position: relative;
    left: ${props => props.left};
    transition: 0.5s ease;
`;

