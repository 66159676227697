import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

export const Card = ({content1, content2, content3, content4, content5, stylep1,stylep2, stylep3, selectObject, url = ""}) => {

  
  return (
    <Link to={url}>
      <div className='card'>
          <div className='line'></div>
            <p className={stylep1}>{content1}</p>
            <p className={stylep1 }>{content2}</p>
            <p className={stylep1}>{content3}</p>
            {selectObject}
            <p className={stylep1 +stylep3}>{content4}</p>
            <p className={stylep1 + stylep2}>{content5}</p>
      </div>
    </Link>
  )
}