import React, {useState, useEffect, useContext} from 'react'
import { MyDeployableMenu } from '../../Styled components/DeployableMenu/DeployableMenu'
import { Link } from 'react-router-dom'
import { MyDinamicBar } from '../../Styled components/DinamicBarStyled/DinamicBarStyled'
import MenuIcon from '../../../Assets/Images/IconoMenu.png'
import StatsIcon from '../../../Assets/Images/IconoEstadisticas.png'
import LogoSena from '../../../Assets/Images/LogoSena.png'
import TurnOff from '../../../Assets/Images/power-button-off.png'
import SubMenu from '../SubMenuComponent/SubMenu'
import CloseBtn from  '../../../Assets/Images/iconoX.png'
import LogoutButton from '../../UI components/LogoutButton/LogoutButton'
import "./MenuComponents.css"
import { Card } from '../../UI components/Card/Card'
import { Context } from '../../../Context/UserContext'

export default function MenuComponent() {

    let menuOptions1 = [
        {"title":"Modificar fechas", "text":"Modificar fechas de los proximos encuentros", "url":"/menu"},
        {"title":"Tablas de clasificación", "text":"Seleccionar tabla de clasificación", "url":"/leagueClasification"},
        {"title":"Eliminar fechas", "text":"Eliminar fechas que no se jugarán" , "url":"/menu"}
    ]

    let menuOptions2 = [
        {"title":"Participantes totales", "text":"Consultar participantes del torneo", "url":"/statistics" },
        {"title":"Fechas restantes", "text":"Aún quedan 23 fechas pendientes", "url":"/statistics"},
        {"title":"Equipos activos de cada jornada", "text":"Mañana: 13    Noche: 12   Tarde: 13", "url":"/statistics" },
        {"title":"Expulsiones totales registradas", "text":"Se han registrado 23 expulsiones", "url":"/statistics"},
        {"title":"Visualizar estadisticas por equipo", "text":"Visualizar estadísticas especificas de un solo equipo", "url":"/statistics" }
    ]

    const [dinamicPosition, setDinamicPosition] = useState("6.7%")
    const [changeMenu, setChangeMenu] = useState(1)
    const [menuOptions, setMenuOptions] = useState(menuOptions1)
    const [closed, setClosed] = useState(false)

    useEffect(() => {
      if(changeMenu == 1){
        setMenuOptions(menuOptions1)
        setClosed(false)
      }
      else if(changeMenu == 2){
        setMenuOptions(menuOptions2)
      }
      else{
        setClosed(true)
      }
    }, [changeMenu])
    

    const changeDinamicPos = (iconPos) =>{
        if(iconPos === 1){
            setDinamicPosition("6.7%")
            setChangeMenu(1)
        }
        else if(iconPos === 2){
            setDinamicPosition("39.8%")
            setChangeMenu(2)
        }
        else{
            setDinamicPosition("72.8%")
            setChangeMenu(3)
        }
    }

    const endSession = () =>{
        console.log("Hola");
        localStorage.clear()
        window.location.reload()
    }

  return (
    <MyDeployableMenu radius="10px" shadowColor="#181818" bgColor="#1b1b1b" margin="5px" closed={closed}>
        {
            changeMenu  != 3 ?
            <>
                <div className='horizontalMenu'>
                    <Link to="/menu">
                        <div className='image_div' onClick={() => {changeDinamicPos(1)}}>
                            <img src={MenuIcon} alt="Icono menu" className='icon'/>
                        </div>
                    </Link>
                    <Link to="/statistics">
                        <div className='image_div' onClick={() => {changeDinamicPos(2)}}>
                            <img src={StatsIcon} alt="Icono estadisticas" className='icon'/>
                        </div>
                    </Link>
                    
                    <div className='image_div' onClick={() => {changeDinamicPos(3)}}>
                    <img src={CloseBtn} alt="Icono cerrar" className='icon'/>
                    </div>
                </div>

                <div className='bar_div'>
                    <MyDinamicBar color="#ff6719" padding="3px" width="20%" radius="3px" left={dinamicPosition}>

                    </MyDinamicBar>
                </div>

                <div className='content_div'>
                    <div className='cards_container scroll_menu'>
                        <SubMenu options={menuOptions}/>
                    </div>

                    <div className='menu_bottom'>
                        <div className='bottomImg_div deleteBackground'>
                            <img src={LogoSena} alt="Logo_sena" className='icon'/>
                        </div>
                        <LogoutButton compStyle='bottomImg_div' onClick={()=>endSession()}/>
                    </div>
                </div>
            </>
            :
            <div className='verticalMenu'>
                <div className='verticalMenu_options'>
                    <div className='image_div' onClick={() => {changeDinamicPos(1)}}>
                        <img src={MenuIcon} alt="Icono menu" className='icon'/>
                    </div>
                    <div className='bottomImg_div deleteBackground'>
                        <img src={LogoSena} alt="Logo_sena" className='icon'/>
                    </div>
                </div>
            </div>
        }
    </MyDeployableMenu>
  )
}
