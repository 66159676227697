import React from 'react'
import TurnOff from '../../../Assets/Images/power-button-off.png'

export default function LogoutButton({...props}) {
  return (
    <button className={props.compStyle} {...props}>
        <img src={TurnOff} alt="Icono_apagado" className='icon cursorPointer'/>
    </button>
  )
}
