import styled from 'styled-components'

export const ShadedBorder = styled.div`
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px;
  border-radius: ${props => props.radius};
  box-shadow: 0px 0px 12px ${props => props.shadowColor};
  background-color: ${props => props.bgColor};
  padding: ${props => props.padding};
  `;
