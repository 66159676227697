import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { ShadedBorder } from '../../components/Styled components/ShadedBorder/ShadedBorder'
import { MyMenuCardStyled } from '../../components/Styled components/MenuCardsStyled/MenuCardsStyled'
import { Context } from '../../Context/UserContext'
import { IoMdAdd } from 'react-icons/io';
import { FiMinus } from 'react-icons/fi'
import teamIcon from '../../Assets/Images/Capa 2 copia.png'
import PlayerComponent from '../../components/UI components/PlayerComponent/PlayerComponent'
import axios from 'axios'

import './TeamComponent.css'
import './MatchPage.css'

export default function MatchPage() {

    const params = useParams()

    const { userData } = useContext(Context);

    const token = userData.token

    const [isLoading, setIsLoading] = useState(null)

    const [teams, setTeams] = useState([])

    const [team2, setTeam2] = useState([])

    const [teamPoints, setTeamPoints] = useState(0)

    const [team2Points, setTeam2Points] = useState(0)

    const [team1, setTeam1] = useState([])

    const [winnerId, setWinnerId] = useState()

    const [loserId, setLoserId] = useState()

    const [winnerPoints, setWinnerPoints] = useState()

    const [loserPoints, setLoserPoints]  = useState()

    const [status, setStatus] = useState(false)


    const updateMatches = async () =>{
        console.log(winnerId,loserId,winnerPoints,loserPoints,"--------------------------");
        await fetch(`https://agtsena.somee.com/api/Partidos/resultado/${params.id}/${winnerId}/${loserId}/${winnerPoints}/${loserPoints}`, {
            method: 'PUT',
            headers:{
                'Content-Type': 'application/json',
                'Access-Control-Allow_Origin':'*',
                'Authorization': `BEARER ${token}`
            }
        })
        .then(response=>{
            console.log(response);
        })
        .catch(error=>
            console.log(error)
        );
    }

    const getWinner = () =>{
        if(teamPoints>team2Points) {
            setWinnerId(team1[0].idEquipo)
            setLoserId(team2[0].idEquipo)
            setWinnerPoints(teamPoints)
            setLoserPoints(team2Points)
        }
        else if(teamPoints<team2Points){
            setWinnerId(team2[0].idEquipo)
            setLoserId(team1[0].idEquipo)
            setWinnerPoints(team2Points)
            setLoserPoints(teamPoints)
        }

        setStatus(true)
    }

    useEffect(() => {
        const matchTeams = async () => {
            setIsLoading(true)
            fetch(`https://agtsena.somee.com/Enfrentamientos/partido/${params.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow_Origin': '*',
                    'Authorization': `BEARER ${token}`
                }
            })
                .then(response => response.json())
                .then(response => {
                    setTeams(response)

                    setTeam1(response?.filter(team => {
                        return team.nombreEquipo == response[0].nombreEquipo
                    }))

                    setTeam2(response?.filter(team => {
                        return team.nombreEquipo != response[0].nombreEquipo
                    }))
                })
                .catch(error =>
                    console.log(error)
                )
                .finally(setIsLoading(false));
        }
        matchTeams()
    }, [])

    useEffect(() => {
        updateMatches()
    }, [status])

    return (
        <div className='gameManagement'>
            {
                isLoading ?
                    <>
                        <p>Cargando...</p>
                    </>
                    :
                    // <>
                    //     {
                    //         team1?.map((item) => (<p>{item.nombreJugador}</p>) )
                    //     }

                    //     {
                    //         team2?.map((item) => (<p>{item.nombreJugador}</p>) )
                    //     }   
                    // </>
                    
                        <ShadedBorder radius="10px" shadowColor="#181818" bgColor="#1b1b1b" margin="5px" closed="false">
                            <div className='match_view'>
                                
                                    {/* <TeamComponent team={team1} teamPointsSetter={setTeamPoints} teamPoints={teamPoints} stylesComp={"componentContainer"} stylesTeammates={"teammates"} /> */}
                                <div>
                                    <div className='baseContainer'>
                                        <div className="componentContainer">
                                            <MyMenuCardStyled radius="10px" shadowColor="#181818" padding="10px ">
                                                <div className='team'>
                                                    <h1>Equipo</h1>
                                                    <div>
                                                        <p>
                                                            {/* {team1[0].nombreEquipo} */}
                                                        </p>
                                                    </div>
                                                    <div className='team_image_div'>
                                                        <img src={teamIcon} alt="imagen_equipo" className='team_image' />
                                                    </div>
                                                </div>
                                            </MyMenuCardStyled>
                                            <div className="teammates">
                                                {
                                                    team1?.map((teammate) => (
                                                        <PlayerComponent playerName={teammate.nombreJugador} />
                                                    ))
                                                }
                                                <div className='marker'>
                                                    <div>
                                                        <button className='reusableBtn removeShadow' onClick={() => { setTeamPoints(teamPoints - 1) }}><FiMinus className='iconsTeam' /></button>
                                                    </div>
                                                    <h2>Puntuación</h2>
                                                    <div>
                                                        <button className='reusableBtn removeShadow' onClick={() => { setTeamPoints(teamPoints + 1) }}><IoMdAdd className='iconsTeam' /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='matchMarker'>
                                    <MyMenuCardStyled radius="10px" shadowColor="#181818" padding="20px" marker="true">
                                        <h1>
                                            {teamPoints}
                                            -
                                            {team2Points}
                                        </h1>
                                    </MyMenuCardStyled>
                                    <input className='reusableBtn cursorPointer' type="submit" value="Terminar partido" onClick={() => getWinner()} />
                                </div>
                               
                                    {/* <TeamComponent team={team2} teamPointsSetter={setTeam2Points} teamPoints={team2Points} stylesComp={"componentContainerLeft"} stylesTeammates={"teammatesRight"} /> */}
                                <div>
                                    <div className='baseContainer'>
                                        <div className="componentContainerLeft">
                                            <MyMenuCardStyled radius="10px" shadowColor="#181818" padding="10px ">
                                                <div className='team'>
                                                    <h1>Equipo</h1>
                                                    <div>
                                                        <p>
                                                            {/* {team2[0].nombreEquipo} */}
                                                        </p>
                                                    </div>
                                                    <div className='team_image_div'>
                                                        <img src={teamIcon} alt="imagen_equipo" className='team_image' />
                                                    </div>
                                                </div>
                                            </MyMenuCardStyled>
                                            <div className="teammatesRight">
                                                {
                                                    team2?.map((teammate) => (
                                                        <PlayerComponent playerName={teammate.nombreJugador} />
                                                    ))
                                                }
                                                <div className='marker'>
                                                    <div>
                                                        <button className='reusableBtn removeShadow' onClick={() => { setTeam2Points(team2Points - 1) }}><FiMinus className='iconsTeam' /></button>
                                                    </div>
                                                    <h2>Puntuación</h2>
                                                    <div>
                                                        <button className='reusableBtn removeShadow' onClick={() => { setTeam2Points(team2Points + 1) }}><IoMdAdd className='iconsTeam' /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ShadedBorder>
                    
            }
        </div>
    )
}
