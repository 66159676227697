import { useContext} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import PreLogedPage from './modules/PreLogedPage/PreLogedPage';
import MenuComponent from './components/Layout components/MenuComponent/MenuComponent';
import MatchPage from './modules/MatchPage/MatchPage';
import { PrincipalPage } from './modules/PrincipalPage/PrincipalPage';
import { Statistics } from './modules/Statistics/Statistics';
import { LeagueLeaderboard } from './modules/LeagueLeaderboard/LeagueLeaderboard.jsx';
import { LeagueClasification } from './modules/LeagueClasification/LeagueClasification';
import { Context } from './Context/UserContext';


function App() {

    const context = useContext(Context)

    return (
        <BrowserRouter>
        {
            context.verifyUserExistance()
            ?
            <>
                <div className='position_elements_menu'>
                    <MenuComponent/>
                    <Routes>
                        <Route path='/menu' element={<PrincipalPage/>}/>
                        <Route path='/statistics' element={<Statistics/>} />
                        <Route path='/leagueLeaderboard/:id' element={<LeagueLeaderboard/>} />
                        <Route path='/match/:id' element={<MatchPage/>} />
                        <Route path='/leagueClasification' element={<LeagueClasification/>} />
                        <Route path='*' element={<PrincipalPage/>}/>
                    </Routes>
                </div>
            </>  
            :
            <>
                <Routes>
                    <Route path='/' element={<PreLogedPage/>}/>
                    <Route path='*' element={<PreLogedPage/>}/>
                </Routes>            
            </>
        }
        </BrowserRouter>
    );
}

export default App;
