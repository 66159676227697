import React, {createContext, useEffect, useState} from 'react'
import axios from 'axios';


export let Context = createContext()

export const UserContext = ({children}) =>{

    const userData = JSON.parse(localStorage.getItem('userInfo'))

    const verifyUserExistance = () =>{
        const userData = JSON.parse(localStorage.getItem('userInfo'))
        
        if(userData!= null && userData.token){
            return true
        }else{
            return false
        }
    }

    let contextData = {userData,verifyUserExistance}

    return(
        <Context.Provider value={contextData}>
            {children}
        </Context.Provider>
    )
}  

 
