import React, {useState, useEffect} from 'react'
import './Button.css'

export default function Button({...props}) {


    return (
        <button className='reusableBtn cursorPointer' {...props}>
            {props.btnName}
        </button>
  )
}
