import React from 'react'
import { MyMenuCardStyled } from '../../Styled components/MenuCardsStyled/MenuCardsStyled'
import './PlayerComponent.css'

export default function PlayerComponent({playerName}) {
    return (
        <MyMenuCardStyled radius="10px" shadowColor="#181818" padding="10px ">
            <p className='name'>{playerName}</p>
        </MyMenuCardStyled>
    )
}
