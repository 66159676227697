import React from 'react'
import MenuCards from '../../UI components/MenuCards/MenuCards'
import './SubMenu.css'

export default function SubMenu({options}) {
  return (
    <div className='cards_container'>
        {
            options.map((option) =>
                <MenuCards option={option} shadowColor="#181818"/>
            )
        } 
    </div>
  )
}
