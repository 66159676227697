import React, {useEffect,useState,useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import {ShadedBorder} from '../../Styled components/ShadedBorder/ShadedBorder'
import Input from '../../UI components/Input/Input'
import Button from '../../UI components/Button/Button'
import ButtonAsLink from '../../UI components/ButtonAsLink/ButtonAsLink'
import './Login.css'
import { Context } from '../../../Context/UserContext'
import axios from 'axios'



export default function Login({state,funct}) {

    const context = useContext(Context)
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const loginUser = async () =>{
        await axios.post("https://agtsena.somee.com/Login",{
            "correo" : email,
            "contrasenna" : password
        })
        .then(response =>{
            console.log(response.data);
            localStorage.setItem("userInfo", JSON.stringify(response.data))
            navigate('/menu')
            window.location.reload()
        })
        .catch(err => {
            console.log(err);
        })
    }

    const enterLogin=(event)=>{
        let charCode = event.keyCode;
        if (charCode===13){
            loginUser()
        }
    }

    return (
        <ShadedBorder radius="10px" shadowColor="#181818" bgColor="#292929" padding="5px">
            <div className='login_div'>
                <h3 className='title_login_form'>Iniciar sesión</h3>
                <div className='infoInputDiv'>
                    <label>Email</label>
                    <Input onChange={e => setEmail(e.target.value)}  type="email" value={email} />        
                    <label>Contraseña</label>
                    <Input onChange={e => setPassword(e.target.value)} onKeyDown={e=>(enterLogin(e))} type="password" value={password}/>        
                </div>
                <Button btnName={"Iniciar Sesión"} onClick={()=>loginUser()}/>
                <div className='link_register'>
                    <ButtonAsLink btnName="¿Aún no te has registrado? Regístrate" isLoginState= {!state} isLoginFunct={funct}/>
                </div>
            </div>
            <div className='line_div'></div>
        </ShadedBorder>
    )
}