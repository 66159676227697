import React, {useEffect,useState, useContext} from 'react'
import './PreLogedPage.css'
import Login from '../../components/Layout components/LoginComponent/Login'
import Registry from '../../components/Layout components/RegistryComponent/Registry'
import ButtonAsLink from '../../components/UI components/ButtonAsLink/ButtonAsLink'
import Sena_desvanecido from '../../Assets/Images/SENA_desvanecido.png'
import Sena_redondeado from '../../Assets/Images/LogoSenaDesvanecido.png'
import { Context } from '../../Context/UserContext'

export default function PreLogedPage() {

    const [isLogin, setIsLogin] = useState(true)

    return (
        <div className='baseBackground'>

            <img className='greetingImage' src={Sena_desvanecido} alt="Logo_Sena_Desvanecido" />

            {/* <img className='SenaImage' src={Sena_desvanecido} alt="Logo_Sena_Colorido"/> */}

            <div className='greetingDiv'>

                <div className='title_div'>
                    <>
                        <p className='TitleFirstWord'>
                            SISTEMA
                        </p>
                        <p className='WholeTitle'>
                            DE CREACIÓN DE
                        </p>  
                        <p className='WholeTitle'>
                            TORNEOS SENA 
                        </p> 
                    </>
                </div>

            </div>

            <div className='renderedComponentDiv'>
                <div className='rendered'>
                    <div>
                        {
                            isLogin ?<Login funct={setIsLogin} state={isLogin}/>:<Registry funct={setIsLogin} state={isLogin}/>
                        }
                    </div>
                    
                </div>
            </div>

        </div>
    )
}