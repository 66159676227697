import React from 'react'
import styled from 'styled-components'

export const MyMenuCardStyled = styled.div`
    cursor: pointer;
    background-color: #292929;
    min-height: 92px;
    width: 100%;
    border-radius: ${props => props.radius};
    box-shadow: 0px 0px 5px 3px ${props => props.shadowColor};
    padding: ${props => props.padding}; 
    display: ${props => props.marker? "flex" : ""};
    align-items: ${props => props.marker? "center" : ""};
    justify-content: ${props => props.marker? "center" : ""};
`;

