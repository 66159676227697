import React, {useState, useEffect} from 'react'
import { MyMenuCardStyled } from '../../Styled components/MenuCardsStyled/MenuCardsStyled'
import './MenuCards.css'
import { Link } from 'react-router-dom'

export default function MenuCards({option}) {

    const [doubleText, setDoubleText] = useState(false)

    useEffect(() => {
        if(option.text2){
            setDoubleText(true)
        }else{
            setDoubleText(false)
        }
    })
    

    return (
        <MyMenuCardStyled radius="5px" shadowColor="#181818" padding="7px">
            <Link to={option.url}>
                <h4>{option.title}</h4>
                <div>
                    <div>
                        <p>
                            {option.text}
                        </p>
                    </div>
                    {
                        doubleText ? 
                        <div>
                            <p>
                                {option.text2}
                            </p>
                        </div>:
                        <></>
                    }
                </div>
            </Link>
        </MyMenuCardStyled>
    
    )
}
