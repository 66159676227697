import React from 'react';
import './DashboardHeaderComponent.css';

export const DashboardHeaderComponent = ({column1, column2, column3, column4, column5, style1, style2, style3, style4}) => {
  return (
    <div className='dashboard_header'>
    <div className='line lineVisibility'></div>
      <p className={style1}>{column1}</p>
      <p className={style1}>{column2}</p>
      <p className={style1}>{column3}</p>
      <p className={style1 + style2}>{column4}</p>
      <p className={style2 + style3}>{column5}</p>
    </div>
  )
}