import React,{useState,useEffect, useContext} from 'react';
import './PrincipalPage.css';
import axios from 'axios';
import { DashboardHeaderComponent } from '../../components/Layout components/DashboardHeaderComponent/DashboardHeaderComponent';
import { DashboardComponent } from '../../components/Layout components/DashboardComponent/DashboardComponent';
import { Select } from '../../components/UI components/Select/Select';
import { ButtonSaveChange } from '../../components/UI components/ButtonSaveChange/ButtonSaveChange';
import { Context } from '../../Context/UserContext';

export const PrincipalPage = () => {
  const [matchs, setMatchs]= useState([]);
  const {userData} = useContext(Context);
  const URL = 'https://agtsena.somee.com/api/Partidos/';

  console.log(userData);

  const userId = userData.data[0].id
  const token = userData.token
  const tournment = userData.torneos[0].id

  const matchsLeagues =()=>{
    axios.get(`https://agtsena.somee.com/Enfrentamientos/${tournment}`, {
      headers:{
        'Content-Type': 'application/json',
        'Access-Control-Allow_Origin':'*',
        'Authorization': `BEARER ${token}`
    }
    })
    .then(response=>{
      setMatchs(response.data)
      console.log(response.data);
    })
    .catch(error=>
      console.log(error)
    );
  }

  // const saveChange = () => {
  //   axios.put( URL`${idPartido}?${idEquipoGanador}`, {
  //     headers:{
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow_Origin':'*',
  //       'Authorization': `BEARER ${token}`
  //   }
  // })
  // .then(response=>{
  //   console.log(response.data);
  // })
  // .catch(error=>
  //   console.log(error)
  // );
  // }
  
  useEffect(() => {
    matchsLeagues()
  }, [])
  
  return (
    <div className='gameManagement'>
      <DashboardHeaderComponent column1="Fecha encuentro" column2="Equipo 1" column3="Equipo 2" column4="Equipo ganador" style1=" spaceColumn " style2=""/>
      <DashboardComponent objectData={matchs} objectSelect={<Select/>} style1=" spaceColumn " style2=" none_spacing " style3=" none_spacing " type={1} />
      {/* <ButtonSaveChange metodo={()=>{saveChange()}}/> */}
    </div>
  )
}