import React ,{useEffect,useState,useContext}from 'react';
import axios from 'axios';
import './LeagueClasification.css';
import { DashboardComponent } from '../../components/Layout components/DashboardComponent/DashboardComponent';
import { DashboardHeaderComponent } from '../../components/Layout components/DashboardHeaderComponent/DashboardHeaderComponent';
import { Context } from '../../Context/UserContext';

export const LeagueClasification = () => {
  const [league, setLeagues]= useState([]);
  const context = useContext(Context);

  const id = context.userData.torneos[0].id

  const token = context.userData.token;
  const leagueClasification =()=>{
    axios.get(`https://agtsena.somee.com/Liguillas/${id}`, {
      headers:{
        'Content-Type': 'application/json',
        'Access-Control-Allow_Origin':'*',
        'Authorization': `BEARER ${token}`
    }
  })
  .then(response=>{
    setLeagues(response.data);
    console.log(response.data);
  })
  .catch(error=>
    console.log(error)
  );
  }

  useEffect(() => {
    leagueClasification()
  }, [])
  return (
    <div className='gameManagement'>
        <div className='text_clasification'>
            <h1>Tablas de Clasificación</h1>
            <p className='text_clasification'>Seleccionar liga para consultar su tabla de clasificación.</p>
        </div>
        <DashboardHeaderComponent column1="Nombre liga" column2="Equipos" column3="Equipo con más puntos" style1="spaceColumn2 " style2="none_spacing"/>
        <DashboardComponent objectData={league} style1="spaceColumn2 " style2="none_spacing" type={2} style3="none_spacing"/>
    </div>
  )
}
