import React from 'react'
import styled from 'styled-components'

export const MyDeployableMenu = styled.div`
    max-width: 358px;
    height: 99vh;
    display: flex;
    align-items: center;
    justify-content: ${props => props.closed? "space-between" : "top"};
    flex-direction: column;
    border: 2px;
    border-radius: ${props => props.radius};
    box-shadow: 0px 0px 12px ${props => props.shadowColor};
    background-color: ${props => props.bgColor};
    margin: ${props => props.margin};
    max-width: 23%;
`;
